import React, { useState, useEffect, useRef } from "react";
import { Link } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import HeroImages from "../components/Reusables/HeroImages";
import SummaryBlock from "../components/Reusables/SummaryBlock";
import StatisticBox from "../components/About/StatisticBox";
import styled from "styled-components";
import StaticAccordion from "../components/About/Accordion2";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import ReactGA from "react-ga";
import axios from "axios";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import Loader from "../components/Reusables/Loader";
const StyledWrapper = styled.div`
  .margin-top {
    margin-top: 50px;
  }

  .bottom-div {
    color: ${props => props.theme["borderBottomColor"]};
  }

  .end-div {
    font-size: 48px;
    color: ${props => props.theme["borderBottomColor"]};
    text-decoration: underline;
    font-weight: 800;
    @media (max-width: 600px) {
      font-size: 28px;
    }
  }
`;

const queryClient = new QueryClient();

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <SecondPage />
    </QueryClientProvider>
  );
}
const fetchData = () => {
  return axios.get(`${process.env.GATSBY_API_URL}/aboutpage`);
};
const SecondPage = () => {
  const [newData, setNewData] = useState(null);
  const { data: pageData } = useQuery("about", fetchData);
  useEffect(() => {
    if (pageData !== undefined) {
      setNewData(pageData.data);
    }
  }, [pageData]);

  const inputRef1 = useRef(null);
  const inputRef2 = useRef(null);
  useEffect(() => {
    if (newData && newData.showSummaryBlock1 === true) {
      inputRef1.current.children[0].children[0].children[0].children[0].children[0].children[2].onclick = function (
        event
      ) {
        ReactGA.event({
          category: "About Page",
          action: "Find Out More Button Click",
        });
      };
      inputRef1.current.children[0].children[0].children[0].children[0].children[0].children[2].onclick = function (
        event
      ) {
        ReactGA.event({
          category: "About Page",
          action: "Previous Winners Button Click",
        });
      };
    }
    if (newData && newData.showSummaryBlock2 === true) {
      inputRef2.current.children[0].children[0].children[0].children[0].children[0].children[4].onclick = function (
        event
      ) {
        ReactGA.event({
          category: "About Page",
          action: "2018/19 Programme Click",
        });
      };
      inputRef2.current.children[0].children[0].children[0].children[0].children[0].children[5].onclick = function (
        event
      ) {
        ReactGA.event({
          category: "About Page",
          action: "2019/20 Programme Click",
        });
      };
    }
  }, []);
  const handleClick = () => {
    ReactGA.event({
      category: "About Page",
      action: "Get in Touch Button Click",
    });
  };
  return (
    <Layout>
      <StyledWrapper>
        {newData === null && <Loader />}
        {newData && <SEO title={newData.SEOtitle} />}
        {newData && newData.showHeroImage && newData.HeroImage && (
          <HeroImages heroImage={newData.HeroImage} />
        )}
        {newData && newData.showSummaryBlock1 && newData.SummaryBlock1 && (
          <div className="white-background" ref={inputRef1}>
            <SummaryBlock summary={newData.SummaryBlock1} />
          </div>
        )}
        {newData && newData.showSummaryBlock2 && newData.SummaryBlock2 && (
          <div ref={inputRef2}>
            <SummaryBlock summary={newData.SummaryBlock2} />
          </div>
        )}
        <div className="white-background">
          {newData && newData.ShowStatisticBlock && (
            <StatisticBox
              data={newData.StatisticBlock}
              StatsHeading={newData.StatisticBlockHeading}
            />
          )}
          {newData && newData.showSummaryBlock3 && newData.SummaryBlock3 && (
            <div className="margin-top">
              <SummaryBlock summary={newData.SummaryBlock3} />
            </div>
          )}
        </div>
        <div className="container-fluid p-0">
          {newData &&
            newData.showSubAccordion &&
            newData.SubAccordion.length > 0 && (
              <StaticAccordion data={newData.SubAccordion} />
            )}
        </div>
        {newData && newData.CtaButton && (
          <div
            className="container-fluid bottom-div mt-0"
            style={{ background: newData.CtaButton.bgColor }}
          >
            <div className="col-12 py-4 d-flex justify-content-center">
              <AnchorLink
                to={newData.CtaButton.link}
                className="end-div"
                onAnchorLinkClick={handleClick}
              >
                {newData.CtaButton.title}
              </AnchorLink>
            </div>
          </div>
        )}
      </StyledWrapper>
    </Layout>
  );
};
