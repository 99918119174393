import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import styled from "styled-components";
import ReactMarkdown from "react-markdown";

const StyledWrapper = styled.div`
  .heading,
  .subtext {
    letter-spacing: ${props => props.theme["zero"]};
    font-family: ${props => props.theme["primaryFont"]} !important;
  }

  .heading {
    color: ${props => props.theme["primaryColor"]} !important;
    font-size: ${props => props.theme["subHeadingSize"]} !important;
    font-weight: ${props => props.theme["headingBold"]} !important;
    line-height: ${props => props.theme["h2LineHeight"]} !important;

    @media (max-width: 600px) {
      font-size: 15px !important;
      line-height: unset !important;
    }
  }

  .sub-heading-light {
    font-family: ${props => props.theme["primaryFont"]} !important;
  }

  .subtext {
    font-size: 20px !important;
    font-weight: ${props => props.theme["textBold"]} !important;
    line-height: ${props => props.theme["subTextLineHeight"]} !important;
    padding-left: 2%;
    padding-right: 2%;
    @media (max-width: 600px) {
      font-size: 14px !important;
    }
    color: black;
  }

  .MuiAccordionDetails-root {
    display: flex;
    padding: 8px 16px 40px;
  }

  .MuiIconButton-label {
    color: #000000;
    font-size: 42px;
  }

  .MuiAccordionSummary-root,
  .MuiAccordionDetails-root {
    padding-left: 0px !important;
  }

  .MuiAccordion-root.Mui-expanded:first-child {
    margin-bottom: 0px;
  }

  .MuiAccordion-root.Mui-expanded:last-child {
    border-bottom: 3px solid #f2b842 !important;
  }

  .MuiAccordion-root:last-child {
    border-bottom: 3px solid #f2b842 !important;
  }

  .accordion-tab {
    border-bottom: 3px solid #f2b842;
    background-color: white;
    box-shadow: none;
  }

  h1 {
    @media (max-width: 600px) {
      line-height: 30px;
    }
  }

  .emp-heading {
    text-transform: uppercase;
    line-height: normal !important;
    flex-basis: 100%;
    font-family: ${props => props.theme["primaryFont"]} !important;
  }

  .react-desc a {
    text-decoration: underline;
    color: black;
  }

  .header-text {
    font-family: ${props => props.theme["primaryFont"]} !important;
    font-size: 36px;
    color: #474cd8;
    @media (max-width: 601px) {
      font-size: 15px;
      line-height: 30px;
    }
  }

  .sub-heading-text {
    font-family: ${props => props.theme["primaryFont"]} !important;
  }
`;

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

function LinkRenderer(props) {
  return (
    <a href={props.href} target="_blank">
      {props.children}
    </a>
  );
}

export default function StaticAccordion({ data }) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(true);

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <StyledWrapper className="white-background pb-3 padding-left-8 padding-right-8">
      {data.length > 0 &&
        data.map(item => (
          <div className="row mr-1 ml-1">
            <div className="col-12 px-0">
              <h2 className="mb-5 header-text">{item.Title}</h2>
            </div>
            <div className="col-12 px-0 subtext">
              <ReactMarkdown source={item.Description} />
            </div>
            <div className={classes.root}>
              {item.Accor.map((item, index) => (
                <Accordion
                  className="accordion-tab"
                  expanded={expanded === item.id}
                  onChange={handleChange(item.id)}
                >
                  <AccordionSummary
                    expandIcon={expanded === item.id ? "-" : "+"}
                    aria-controls={`panel${item.id}bh-content`}
                    id={`panel${item.id}bh-header`}
                  >
                    <Typography
                      className={classes.heading + " heading emp-heading"}
                    >
                      {item.Title}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography
                      className={classes.secondaryHeading + " subtext pl-0"}
                    >
                      <div className="row">
                        <div className="react-desc col-12">
                          <ReactMarkdown source={item.Description} />
                        </div>
                      </div>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
              <br />
              <br />
            </div>
            <br />
            <br />
          </div>
        ))}
    </StyledWrapper>
  );
}
